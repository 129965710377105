<script setup>
import logo from "@/assets/img/logo/medziobites-greyscale.svg"
import {useRoute} from "vue-router"
import {ref} from "vue"

const routeCurrent = useRoute()
const route = ref(routeCurrent)

</script>
<template>
  <div class="category-title no-border is-padded">
    <h2 v-if="$route?.meta?.title">{{ $t($route.meta.title) }}</h2>
    <img :src="logo" alt="logo" class="brand-filigrane"/>
  </div>
  <AmBreadcrumbs :showCurrentCrumb="true"/>
</template>
