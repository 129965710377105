import LayoutDefault from "@/layouts/default.vue"
import LayoutBlank from "@/layouts/blank.vue"
import LayoutAuth from "@/layouts/auth.vue"
import router from '../router/index'
import {computed} from "vue";

const layout = computed(() => {
    switch (router.currentRoute.value.meta.layout) {
        case 'blank':
            return LayoutBlank
        case 'auth':
            return LayoutAuth
        case 'default':
            return LayoutDefault
        default:
            return LayoutBlank
    }
})
export default layout