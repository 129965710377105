export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/login.vue'),
        meta: {
            layout: 'auth',
            requiresAuth: false,
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/auth/register.vue'),
        meta: {
            layout: 'auth',
            requiresAuth: false,
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/auth/forgotPassword.vue'),
        meta: {
            layout: 'auth',
            requiresAuth: false,
        },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/views/auth/resetPassword.vue'),
        meta: {
            layout: 'auth',
            requiresAuth: false,
        },
    },
]