{
  "global": {
    "mainTitle": "Medžio bitės | didmena",
    "offline": "Nėra interneto ryšio.",
    "tokenExpired": "Baigėsi Jūsų prisijungimo sesija. Prisijunkite iš naujo.",
    "back": "Atgal",
    "home": "Pradžia",
    "empty list": "Šiuo metė nėra prekių"
  },
  "login": {
    "email": "El. pašto adresas",
    "password": "Slaptažodis",
    "remember": "Prisiminti?",
    "signin": "Prisijungti",
    "forgotpass": "Pamiršote slaptažodį?",
    "emailError": "Prašome nurodyti el. pašto adresą.",
    "passError": "Reikalingas slaptažodis.",
    "emailError2": "Nurodykite teisingą el. pašto adresą",
    "passError2": "arba neteisingas slaptažodis",
    "remindMessage": "Įvęskite el. pašto adresą. Nusiųsime Jums instrukciją kaip keisti slaptažodį.",
    "send": "Siųsti",
    "backToLogin": "Atgal į prisijungimą",
    "enterEmailCode": "Įvęskite el. paštu nusiųstą aktyvacijos kodą ir naują slaptažodį.",
    "passcode": "Aktyvacijos kodas",
    "newPass": "Naujas slaptažodis",
    "buttonChange": "Keisti",
    "codeSent": "Pasitikrinkite el. pašto dėžutę",
    "enterNewPass": "Įrašykite naują slaptažodį",
    "newPassError": "Turi būti ne mažiau 8 simbolių",
    "codeError": "Neteisingas arba nebegaliojantis kodas",
    "resetSuccess": "Puiku, naujas slaptažodis sukurtas. Dabar galite prisijungti!",
    "wrongCode": "Neteisingas arba nebegaliojantis kodas",
    "newPassSuccess": "Puiku, naujas slaptažodis sukurtas. Dabar galite prisijungti!"
  },
  "register": {
    "code": "Įmonės kodas",
    "vatCode": "PVM kodas",
    "representative": "Įmonės atstovas",
    "name": "Vardas",
    "surname": "Pavardė",
    "email": "El. paštas",
    "phone": "Tel. numeris",
    "newsletter": "Prenumeruoti naujienlaiškį?",
    "buttonRegister": "Registruotis",
    "codeError": "Prašome nurodyti įmonės kodą",
    "nameError": "Kuo Jūs vardu?",
    "surnameError": "Jūsų pavardė",
    "emailError": "El. pašto adresas privaloma",
    "phoneError": "Įrašykite kontaktinį tel. numerį",
    "errorsError": "Prašome ištaisyti klaidas.",
    "codeErrorWrong": "Neteisingas įmonės kodas",
    "vatCodeError": "Neteisingas PVM kodas",
    "nameErrorWrong": "Neteisingas vardas",
    "surnameErrorWrong": "Neteisinga pavardė",
    "emailErrorWrong": "Neteisingas el. pašto adresas",
    "emailErrorExists": "Nurodykite teisingą el. pašto adresą",
    "phoneErrorWrong": "Neteisingas tel. numeris",
    "registrationSuccess": "Jūsų registracija priimta. Administratorius patikrins duomenis ir informuos nurodytu el. paštu."
  },
  "account": {
    "account": "Paskyra",
    "details": "Paskyros duomenys",
    "name": "Vardas",
    "surname": "Pavardė",
    "email": "Paštas",
    "phone": "Tel. nr.",
    "ordersstat": "Sukurta / Įmonės užsakymai",
    "debt_limit": "Jūsų įmonė turi skolą didesnę nei suteiktas kreditas. Prašome padengti solą.",
    "errorName": "Privaloma, 3 ir daugiau simbolių",
    "errorSurname": "Privaloma, 3 ir daugiau simbolių",
    "errorEmail": "Negalima keisti",
    "errorPhone": "Privaloma, 9 ir daugiau simbolių",
    "accountUpdated": "Duomenys atnaujinti"
  },
  "company": {
    "details": "Įmonės duomenys",
    "title": "Pavadinimas",
    "code": "Kodas",
    "vatcode": "PVM kodas",
    "street": "Gatvė, Nr",
    "postcode": "Pašto kodas",
    "city": "Miestas",
    "country": "Šalis",
    "email": "El. paštas",
    "phone": "Tel. numeris",
    "www": "www",
    "limit": "Limitas",
    "debt": "Skola"
  },
  "orders": {
    "summary": "Santrauka",
    "generate": "Jūsų važtaraštis kuriamas, palaukite",
    "title": "Užsakymai",
    "noOrders": "Nėra užsakymų",
    "noOrdersMessage": "Jūs dar neatlikote bent vieno pirkimo. Jūsų užsakymų duomenys bus pateikti čia.",
    "continueShopping": "Tęsti pirkimą",
    "order": "Užsakymas",
    "complete": "Atlikta",
    "status": {
      "0": "Peržiūrima",
      "1": "Registruota",
      "2": "Tikrinima",
      "3": "Apmokėjimo statusas",
      "4": "Prekių rezervacija",
      "5": "Prekių surinkimas",
      "6": "Vežėjo iškvietimas",
      "7": "Prekių išsiuntimas",
      "8": "Prekių pristatymas",
      "9": "Vykdoma",
      "10": "Vykdoma",
      "11": "Vykdoma",
      "12": "Paruošta pasiimti",
      "13": "Atlikta",
      "19": "Išvežta į skyrių",
      "90": "Atšaukta"
    },
    "measure": {
      "1": "vnt.",
      "2": "",
      "3": "",
      "5": "m",
      "6": "m³"
    },
    "details": "Užsakymo duomenys",
    "products": "Prekės",
    "product": "Prekė",
    "quantity": "Kiekis",
    "price": "Kaina",
    "price_m3": "Kaina m³",
    "discount": "Nuolaida",
    "volume": "m³",
    "total": "Suma",
    "issued": "Užsakymo data",
    "manager": "Vadybininkas",
    "shipping_address": "Pristatymo adresas",
    "assistance": "Pagalba",
    "print_order": "Spausdinti",
    "payment_information": "Užsakymo suvestinė",
    "weight": "Svoris, kg",
    "order_subtotal": "Užsakymo tarpinė suma",
    "order_shipping": "Užsakymo pristatymas",
    "order_tax": "Užsakymo PVM",
    "taxes": "Mokesčiai",
    "order_total": "Užsakymo suma",
    "action": "Veiksmas"
  },
  "products": {
    "groups": "Prekių grupės",
    "more": "Daugiau",
    "products": "Produktai",
    "subgroups": "Pogrupiai",
    "packs": "Pak.",
    "length": "Ilgis",
    "error_parcel_reserved": "Šį paketą jau rezervavo kitas pirkėjas.",
    "filter": "Filtras",
    "category": "Produktų kategorijos"
  },
  "cart": {
    "title": "Krepšelis",
    "empty": "Tuščias krepšelis",
    "empty_summary": "Jūsų pirkinių krepšelis tuščias. Kraukite prekes į krepšelį ir atlikite užsakymą.",
    "num_items": "Prekė(s) krepšelyje",
    "under_manager": "Paskaičiuosime",
    "checkout": "Pateikti rezervaciją",
    "notes_title": "Užsakymo pastabos",
    "notes_summary": "Įvęskite šiam užsakymui pastabas (adresas, pristatymo laikas ir t.t.) ar kitą svarbią informaciją (pasirinktinai)",
    "no_products": "Negalima atlikti užsakymo, kaii krepšelyje nėra prekių.",
    "time_left": "Iki rezervacijos pabaigos",
    "susses_order_created": "užsakymas sukurtas",
    "your_order": "Jūsų užsakymas"
  },
  "pages": {
    "account": "Paskyra",
    "account_edit": "Redaguoti paskyrą"
  },
  "validator": {
    "required_name": "Koks tavo vardas?"
  }
}