<script setup>
import {inject, ref} from "vue"
import {useI18n} from "vue-i18n"
import {setLocale} from '@vee-validate/i18n'

const emitter = inject('emitter')
const {locale} = useI18n()
const showLanguageList = ref(false)
const languages = [
  {name: 'Lietuvių', code: 'lt'},
  {name: 'English', code: 'en'},
  {name: 'Polski', code: 'pl'}
]
const changeLocale = (code) => {
  locale.value = code
  showLanguageList.value = false
  setLocale(code);
  localStorage.setItem('locale', code)
  window.location.reload();
}

emitter.on('showLangList', () => {
  showLanguageList.value = !showLanguageList.value
})

</script>
<template>
  <div class="menu-fab " @click="showLanguageList = !showLanguageList"
       :class="{'active-mobile-button':showLanguageList}">
    <a class="hamburger-btn">
          <span class="menu-toggle">
            <span :class="{ 'active' : showLanguageList }" class="icon-box-toggle">
              <span class="rotate">
                <i class="icon-line-top"></i>
                <i class="icon-line-center"></i>
                <i class="icon-line-bottom"></i>
              </span>
              <span class="language-active text-uppercase" v-text="locale"></span>
            </span>
          </span>
    </a>
  </div>
  <div :class="{ 'is-active' : showLanguageList }" class="language-quickview">
    <div class="inner">
      <ul class="language-menu">
        <li v-for="lang in languages">
          <a @click.prevent="changeLocale(lang.code)">
            {{ lang.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
