{
  "global": {
    "mainTitle": "Timber bees | wholesale",
    "offline": "No internet connection.",
    "tokenExpired": "Your session time expired. Please sign in back.",
    "back": "Back",
    "home": "Home",
    "empty list": "List is empty"
  },
  "login": {
    "email": "Email address",
    "password": "Password",
    "remember": "Remember?",
    "signin": "Sign In",
    "forgotpass": "Forgot password?",
    "emailError": "What is you email address?",
    "passError": "Enter password.",
    "emailError2": "Wrong email address",
    "passError2": "or wrong password",
    "remindMessage": "Enter email address. We will send an instruction how to reset your password.",
    "send": "Send",
    "backToLogin": "Back to sign in",
    "enterEmailCode": "Please enter activation code received by email and enter new password.",
    "passcode": "Activation code",
    "newPass": "New password",
    "buttonChange": "Change",
    "codeSent": "Check email inbox",
    "enterNewPass": "Enter new password",
    "newPassError": "Should be at least 8 symbols",
    "codeError": "Wrong or expired activation code",
    "resetSuccess": "Great, new password created. Now can sign in!",
    "wrongCode": "Incorrect or expired code",
    "newPassSuccess": "Great, new password is created. Now you can sign in!"
  },
  "register": {
    "code": "Company code",
    "vatCode": "VAT code",
    "representative": "Company representative",
    "name": "Name",
    "surname": "Surname",
    "email": "Email",
    "phone": "Phone",
    "newsletter": "Subscribe newsletter?",
    "buttonRegister": "Register",
    "codeError": "Enter company code",
    "nameError": "Your name",
    "surnameError": "Your surname",
    "emailError": "Email address required",
    "phoneError": "Phone number required",
    "errorsError": "Please correct errors",
    "codeErrorWrong": "Wrong company code",
    "vatCodeError": "Wrong VAT code",
    "nameErrorWrong": "Wrong name",
    "surnameErrorWrong": "Wrong surname",
    "emailErrorWrong": "Wrong email address",
    "emailErrorExists": "Email address already registered",
    "phoneErrorWrong": "Wrong phone number",
    "registrationSuccess": "Your registration accepted. Administrator will check your data and inform you by email."
  },
  "account": {
    "account": "Account",
    "details": "Account details",
    "name": "First Name",
    "surname": "Last Name",
    "email": "Email",
    "phone": "Phone",
    "ordersstat": "Created / Company orders",
    "debt_limit": "Your company have debt over given credit limit. Please cover debt.",
    "errorName": "Required, min. length 3 symbols",
    "errorSurname": "Required, min. length 3 symbols",
    "errorEmail": "You can't change email address",
    "errorPhone": "Required, min. length 9 symbols",
    "accountUpdated": "Account updated"
  },
  "company": {
    "details": "Company details",
    "title": "Title",
    "code": "Code",
    "vatcode": "VAT Code",
    "street": "Street, No",
    "postcode": "Post code",
    "city": "City",
    "country": "Country",
    "email": "Email",
    "phone": "Phone",
    "www": "www",
    "limit": "Limit",
    "debt": "Debt"
  },
  "orders": {
    "summary": "Summary",
    "generate": "Your pdf file is being created, please wait",
    "title": "Orders",
    "noOrders": "No Orders",
    "noOrdersMessage": "You have no orders to show. Your order details will be stored here.",
    "continueShopping": "Continue Shopping",
    "order": "Order",
    "complete": "Completed",
    "status": {
      "0": "Under review",
      "1": "Registered",
      "2": "Checking",
      "3": "Payment status",
      "4": "Products reservation",
      "5": "Collecting products",
      "6": "Call Carrier",
      "7": "Shipping",
      "8": "Shipped",
      "9": "Processing",
      "10": "Processing",
      "11": "Processing",
      "12": "Ready to pickup",
      "13": "Completed",
      "19": "Moved to shop",
      "90": "Canceled"
    },
    "measure": {
      "1": "qty.",
      "2": "",
      "3": "",
      "5": "m",
      "6": "m³"
    },
    "details": "Order details",
    "products": "Products",
    "product": "Product",
    "quantity": "Quantity",
    "price": "Price",
    "price_m3": "Price m³",
    "discount": "Discount",
    "volume": "m³",
    "total": "Total",
    "issued": "Issued on",
    "manager": "Handled by",
    "shipping_address": "Shipping Address",
    "assistance": "Assistance",
    "print_order": "Print Order",
    "payment_information": "Payment Information",
    "weight": "Weight, kg",
    "order_subtotal": "Order subtotal",
    "order_shipping": "Order shipping",
    "order_tax": "Order TAX",
    "taxes": "Taxes",
    "order_total": "Order total",
    "action": "Action"
  },
  "products": {
    "groups": "Products groups",
    "more": "More",
    "products": "Products",
    "subgroups": "Sub groups",
    "packs": "Packs",
    "length": "Length",
    "error_parcel_reserved": "This package has already been booked by another buyer.",
    "filter": "Filter",
    "category": "Products categories"
  },
  "cart": {
    "title": "My cart",
    "empty": "Empty Cart",
    "empty_summary": "Your shopping cart is currenlty empty. Start adding products to be able to checkout.",
    "num_items": "Item(s) in Cart",
    "under_manager": "Will calculate",
    "checkout": "Make a reservation",
    "notes_title": "Order Notes",
    "notes_summary": "Enter some special instructions (address, delivery time and etc.) to be attached to your order (optional)",
    "no_products": "Can't place an order when no products in a cart.",
    "time_left": "Reservation will end in",
    "susses_order_created": "was created",
    "your_order": "Your order"
  },
  "pages": {
    "account": "Account",
    "account_edit": "Update account"
  },
  "validator": {
    "required_name": "What is your name?"
  }
}