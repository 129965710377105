import {createI18n} from 'vue-i18n'

const messages = Object.fromEntries(Object.entries(
    import.meta.glob('./locales/*.json', {eager: true}))
    .map(([key, value]) => [key.slice(10, -5), value.default]))

const lang = localStorage.getItem('locale') ? localStorage.getItem('locale') : import.meta.env.VITE_DEFAULT_LOCALE

export default createI18n({
    legacy: false,
    locale: lang,
    fallbackLocale: import.meta.env.VITE_DEFAULT_LOCALE,
    messages,
})


