const makeHashData = (data) => {
    const hashedData = btoa(unescape(encodeURIComponent(JSON.stringify(data))))
    return {
        data: hashedData
    }
}

const transformRegisterDataWithHash = (data) => {
    data['login'] = data.email
    data['full-name'] = data.name
    const transformedData = JSON.stringify(Object.assign({}, data))
    const hashedData = btoa(unescape(encodeURIComponent(transformedData)))
    return {
        data: hashedData
    }
}

const transformUpdateAccountData = (data) => {
    const transformedUpdateData = {
        'full-name': data.name,
        surname: data.surname,
        email: data.email,
        phone: data.phone,
        title: data.companyTitle,
        code: data.companyCode,
        vatcode: data.companyVatCode,
        street: data.companyStreet,
        postcode: data.companyPostCode,
        city: data.companyCity,
        country: data.companyCountry,
        cemail: data.companyEmail,
        cphone: data.companyPhone,
        www: data.companyWww,
        creditlimit: data.companyLimit,
        debt: data.companyDebit,
    }

    const hashedData = btoa(unescape(encodeURIComponent(JSON.stringify(transformedUpdateData))))
    return {
        data: hashedData
    }
}


export {
    makeHashData,
    transformRegisterDataWithHash,
    transformUpdateAccountData,
}