import i18n from "@/plugins/i18n";

const {t} = i18n.global
export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/shop/categoriesList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            title: 'global.home',
            breadcrumb: t('global.home')
        },
    },
    {
        path: '/:slug',
        component: () => import('@/views/shop/subCategoriesList.vue'),
        name: 'category',
        meta: {
            requiresAuth: true,
            layout: 'default',
            title: 'products.groups',
            breadcrumb(route, app) {
                return route.meta.title
            },

        }
    },
    {
        path: '/:slug/:category',
        component: () => import('@/views/shop/productList.vue'),
        name: 'products',
        meta: {
            requiresAuth: true,
            layout: 'default',
            title: 'products.products',
            breadcrumb(route, app) {
                return route.meta.title
            },
        }
    },
    {
        path: '/:slug/:category/:id',
        component: () => import('@/views/shop/product.vue'),
        name: 'product',
        meta: {
            requiresAuth: true,
            layout: 'default',
            title: 'orders.product',
            breadcrumb(route, app) {
                return route.meta.title
            },
        }
    },
    {
        path: '/orders',
        name: 'orders',
        component: () => import('@/views/order/orderList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            title: 'orders.title',
            breadcrumb: {label: t('orders.title')}

        },
    },
    {
        path: '/orders/:uuid',
        name: 'single-order',
        component: () => import('@/views/order/orderSingle.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            title: 'orders.details',
            breadcrumb(route, app) {
                return route.meta.title
            },

        }
    },
    {
        path: '/cart',
        name: 'cart',
        component: () => import('@/views/shop/cart.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            title: 'cart.title',
            breadcrumb: {label: t('cart.title')}
        }
    },
]