import {computed, ref} from 'vue'
import {defineStore} from 'pinia'
import axios from "@/plugins/axios";
import {useRouter} from 'vue-router'
import {makeHashData, transformRegisterDataWithHash, transformUpdateAccountData} from "@/helpers/dataTransform";

export const useUserStore = defineStore('auth', () => {
    const user = ref({})
    const isAuth = ref(false)
    const authUser = computed(() => user.value)
    const router = useRouter()

    const login = data =>
        axios
            .post('login', makeHashData(data), {headers: {'X-Authorization': 'tryLogin'}})
            .then(response => {
                const {token} = response.data
                localStorage.setItem('token', token)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                isAuth.value = true
                getAuthUser()
            });


    const getAuthUser = async () => {
        const response = await axios.get('account')
        user.value = response.data;
        isAuth.value = true;
    };

    const register = data => axios.post('signup', transformRegisterDataWithHash(data), {headers: {'X-Authorization': 'trySignup'}})


    const forgotPassword = data => axios.post('reset', makeHashData(data), {headers: {'X-Authorization': 'tryRemind'}})


    const resetPassword = data => axios.post('setpass', makeHashData(data), {headers: {'X-Authorization': 'tryReset'}})


    const logout = () =>
        axios
            .post('invalidate', {token: localStorage.getItem('token')}, {headers: {'X-Authorization': 'token'}})
            .finally(() => {
                removeUserData()
                router.push({name: 'login'})
            });


    const removeUserData = () => {
        isAuth.value = false
        user.value = {}
        localStorage.removeItem('token')
    }

    const update = data => {
        return axios.post('account', transformUpdateAccountData(data))
    }


    return {
        user,
        isAuth,
        authUser,
        login,
        forgotPassword,
        resetPassword,
        register,
        logout,
        getAuthUser,
        update,
        removeUserData
    }
})
