<script setup>
import {RouterView, useRoute} from "vue-router"
import layout from "./helpers/layouts";
import {useI18n} from "vue-i18n"
import {setLocale} from "@vee-validate/i18n"
import {useUserStore} from "@/stores/user";
import ReservationAlert from "@/components/global/reservationAlert.vue";

const userStore = useUserStore()
const {locale} = useI18n()
const route = useRoute()

userStore.getAuthUser().catch(() => console.log('Invalid token or expired'))
setLocale(locale.value)

</script>
<template>
  <reservation-alert/>
  <component :is="layout">
    <RouterView/>
  </component>
</template>

<style lang="scss">
@import "src/assets/scss/app";
</style>