import axios from 'axios'
import {useToast} from "vue-toastification";
import i18n from '../plugins/i18n'
import {useUserStore} from "@/stores/user";
import router from "@/router";

const {t} = i18n.global
const toast = useToast()
const axiosIns = axios.create({
    baseURL: import.meta.env.VITE_API_DEFAULT_URL,
    headers: {
        'Locale': localStorage.getItem('locale') ? localStorage.getItem('locale') : import.meta.env.VITE_DEFAULT_LOCALE,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
})

axiosIns.defaults.withCredentials = false
axiosIns.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    },
)

axiosIns.interceptors.response.use(
    function (config) {
        return config
    },
    function (error) {

        const userStore = useUserStore();
        if (error.response.status === 401 && error.response.data.error === "token_expired") {
            toast.error(t('global.tokenExpired'))
            router.push({name: 'login'})
            userStore.removeUserData()
        }
        if (error.response.status === 400 &&
            error.response.data.error === "token_not_provided" && router.currentRoute.value.meta.requiresAuth ||
            error.response.data.error === "token_invalid" && router.currentRoute.value.meta.requiresAuth
        ) {
            router.push({name: 'login'})
            userStore.removeUserData()
        }
        return Promise.reject(error)
    },
)

export default axiosIns
