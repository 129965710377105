<script setup>
import {useUserStore} from "@/stores/user"
import VueCountdown from '@chenfengyuan/vue-countdown'
import {computed, inject, ref} from "vue"

const emitter = inject('emitter')
const userStore = useUserStore()
const alertCartToast = computed(() => userStore.authUser.alertCartToast)
const timeLeft = computed(() => alertCartToast.value * 1000)
const countdownRef = ref(null)

emitter.on('restartCounter', () => {
  countdownRef.value.restart()
})
</script>
<template>
  <vue-countdown tag="div" :time="timeLeft || 0" v-slot="{ minutes,seconds,totalSeconds}" ref="countdownRef"
                 class="countdown-wparp">
    <div v-if="alertCartToast && totalSeconds" class="reservation_alert">
      <div class="reservation_alert_txt">
        {{ $t("cart.time_left") }}:
        {{ minutes }}:{{ seconds }}
      </div>
    </div>
  </vue-countdown>
</template>
<style lang="scss">
.reservation_alert {
  background-color: #ffc107;
  color: #fff;
  position: fixed;
  bottom: 2%;
  right: 2%;
  z-index: 4;
  width: 310px;
  padding: 8px 10px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
}
</style>