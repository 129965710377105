import {defineRule} from 'vee-validate'
import {email, min, required} from "@vee-validate/rules"
import i18n from '../i18n'
const {t} = i18n.global
defineRule('required_name', value => {
  if (!value || !value.length) {
    return t('validator.required_name');
  }
  return true;
});

defineRule('required', required)
defineRule('email', email)
defineRule('min', min)

