import {createRouter, createWebHistory} from 'vue-router'
import auth from "@/router/auth";
import account from "@/router/account";
import shop from "@/router/shop";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        ...auth,
        ...account,
        ...shop
    ]
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    if (to.matched.some(record => record.meta.requiresAuth) && !token)
        return next({name: 'login'})
    if (token && !to.meta.requiresAuth)
        return next({name: 'home'})
    next()
})

export default router
