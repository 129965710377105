<script setup>
import {RouterView, useRouter} from "vue-router"
import {ref, watchEffect} from "vue"
import logo from '@/assets/img/logo/medzio-bites-logo-new.svg'

const router = useRouter()
const ifRegisterPage = ref(false)

watchEffect(() => {
  if (router.currentRoute.value.name === 'register') ifRegisterPage.value = true
  if (router.currentRoute.value.name === 'login') ifRegisterPage.value = false
});

const switchPageAuthForm = (e) => {
  if (!e.target.checked) return router.push({name: 'login'})
  return router.push({name: 'register'})
}

</script>
<template>
  <div id="loginForm" class="shop-wrapper">
    <div id="shop-authentication" class="section">
      <div class="container-fluid">
        <div class="columns account-header is-auth">
          <div class="column main-column is-tablet-landscape-padded">
            <div class="auth-title">
              <img :src="logo" alt="logo" style="max-width: 120px"/>
            </div>
            <div class="auth-toggler">
              <input id="loginType" type="checkbox" value="0" @change="switchPageAuthForm" v-model="ifRegisterPage">
              <div class="toggler-track">
                <div class="hexagon-wrap">
                  <div class="hexagon">
                    <i class="bi bi-person login-icon"></i>
                    <i class="bi bi-person-fill-add register-icon text-white"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="main-auth-wrapper">
              <RouterView/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>