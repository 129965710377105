import i18n from "@/plugins/i18n";

const {t} = i18n.global
export default [
    {
        path: '/account',
        name: 'account',
        component: () => import('@/views/account/account.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            title: 'pages.account',
            breadcrumb: {label: t('pages.account')}
        },
    },
    {
        path: '/account/edit',
        name: 'account-edit',
        component: () => import('@/views/account/edit.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            title: 'pages.account_edit',
            breadcrumb: {label: t('pages.account_edit')}
        },
    }
]