import "bootstrap"
import {createApp} from 'vue'
import {createPinia} from 'pinia'
import i18n from "@/plugins/i18n"
import './plugins/validator/locale'
import App from './App.vue'
import router from './router'
import Toast from "vue-toastification"
import breadcrumbs from 'vue-3-breadcrumbs'
import "vue-toastification/dist/index.css"
import '@/plugins/validator/validation'
import VueEasyLightbox from 'vue-easy-lightbox'
import mitt from 'mitt'

const emitter = mitt()
const app = createApp(App)
// App use libs
app.use(router)
app.use(breadcrumbs, {includeComponent: true})
app.use(Toast);
app.use(createPinia())
app.use(i18n)
app.use(VueEasyLightbox)
app.provide('emitter', emitter);
app.mount('#app')