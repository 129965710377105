<script setup>
import {useUserStore} from "@/stores/user"

const userStore = useUserStore()
const logout = () => userStore.logout()

</script>
<template>
  <a class="sidebar_item" @click.prevent="logout">
    <i class="bi bi-box-arrow-left"></i>
  </a>
</template>
